import React from 'react';
import { connect } from "react-redux"
import { QrReader } from 'react-qr-reader'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  FormGroup,
  Label,
  Input
} from "reactstrap"
import {
  Cast
} from "react-feather"
import { getOrderDetails, setOrderDetails } from "redux/actions/orders"
import { getLockersBySize } from "redux/actions/locations"
import { toast } from "react-toastify"
import { withTranslation } from 'react-i18next'
import 'assets/scss/components/barcode-scanner.scss'
import DeliverOrderModal from "components/Delivery/DeliverOrderModal"
import SweetAlert from 'react-bootstrap-sweetalert'
import { ReactComponent as QrCodeIcon } from 'assets/img/svg/qr-code-icon.svg';

class ScanOrderQrCode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scanning: false,
      showDeliveryModal: false,
      lockerSize: 'm',
      confirmRegisterOrderAlert: false,
      showRegisterOrderModal: false,
      newRecipientPhone: '',
      newPackageId: '',
      isSubmitting: false,
    }
  }

    handleScannerResult = (result) => {
      if(result !== undefined) this.onScanSuccess(result);
    }

    onScanSuccess = (result) => {
      if(!this.state.isSubmitting){
        this.toggleScanner()
        this.setState({newRecipientPhone: '', newPackageId: '', newRecipientNumber: ''})
        this.props.dispatch(getOrderDetails(this.props.accessToken, result, true)).then((res) => {
          if(res === 'orderDelivered'){
            toast.error(this.props.t("notifications.orders.orderAlreadyDelivered"))
            this.toggleDeliveryModal()
          }else{
            this.toggleDeliveryModal()
          }
        }).catch((err) => {
          if(err === 'orderNotExist'){
            if(
              result.substring(0,4).toUpperCase() === 'SGPT'
            ){
              this.setState({newPackageId: result}, () => {
                this.toggleConfirmRegisterOrderAlert()
              })
            }else{
              toast.error(this.props.t("notifications.orders.errorFetchingData"))
            }
          }else{
            toast.error(this.props.t("notifications.orders.errorFetchingData"))
          }
        })
      }
    }

    toggleConfirmRegisterOrderAlert = () => {
      this.setState({confirmRegisterOrderAlert: !this.state.confirmRegisterOrderAlert})
    }

    toggleRegisterOrderModal = () => {
      this.setState({showRegisterOrderModal: !this.state.showRegisterOrderModal})
    }

    toggleDeliveryModal = () => {
      this.props.dispatch(getLockersBySize(this.props.accessToken, true))
      this.setState(prevState => ({
        showDeliveryModal: !prevState.showDeliveryModal
      }))
    }

    validateFields = () => {
      var phoneRegex = /^\d{9}$/

      if(
        this.state.newRecipientPhone.match(phoneRegex)
        && (
          this.state.newPackageId.substring(0,4).toUpperCase() === 'SGPT'
        )
        && (
          this.state.newRecipientNumber.substring(0,3).toUpperCase() === 'PRT'
          || this.state.newRecipientNumber.substring(0,2).toUpperCase() === 'PT'
        )
      ){
        return true
      }else{
        return false
      }
    }

    toggleScanner = () => {
      this.setState(prevState => ({
        scanning: !prevState.scanning
      }))
    }

    changeRecipientPhone = (e) => {
      this.setState({newRecipientPhone: e.target.value});
    }

    changeRecipientNumber = (e) => {
      this.setState({newRecipientNumber: e.target.value});
    }

    saveOrderDetails = () => {
      if(this.validateFields()){
        this.setState({isSubmitting: true})
        this.props.dispatch(setOrderDetails(this.props.accessToken, {id: 0, packageId: this.state.newPackageId, recipientPhone: this.state.newRecipientPhone, recipientEmail: '', recipientNumber: this.state.newRecipientNumber})).then(()=>{
          toast.success(this.props.t("notifications.orders.setSuccess"))
          this.props.dispatch(getOrderDetails(this.props.accessToken, this.state.newPackageId, true)).then(() => {
            this.toggleRegisterOrderModal()
            this.toggleDeliveryModal()
          }).catch((err) => {
              toast.error(this.props.t("notifications.orders.errorFetchingData"))
          })
        }).catch((err) => {
          toast.error(this.props.t("notifications.orders.setError"))
        }).finally(() => {
          this.setState({isSubmitting: false})
        })
      }else{
        toast.error(this.props.t("notifications.orders.invalidInput"))
      }
    }

    render(){
      const { t } = this.props
      const confirmRegisterOrderAlert = <SweetAlert title={t('deliveryModal.orderNotExist')}
        warning
        show={this.state.confirmRegisterOrderAlert}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText={t('deliveryModal.confirm')}
        cancelBtnText={t('deliveryModal.cancel')}
        onConfirm={() => {
          this.toggleConfirmRegisterOrderAlert()
          this.toggleRegisterOrderModal()
        }}
        onCancel={() => this.toggleConfirmRegisterOrderAlert()}
      >
        {t('deliveryModal.confirmRegisterOrder')}
      </SweetAlert>

      const registerOrderModal = (
        <>
        <Modal
        isOpen={this.state.showRegisterOrderModal}
        toggle={this.toggleRegisterOrderModal}
        className="modal-dialog-centered modal-lg"
        >
          <ModalHeader toggle={this.toggleRegisterOrderModal} className="bg-primary">
          {t('barcodeScanner.registerOrder')}
          </ModalHeader>
          <ModalBody className="delivery-modal">
            <Col lg="3" sm="12">
              <FormGroup>
                <Label for="packageId">{t("orderDetails.packageId")}</Label>
                <Input
                  disabled={true}
                  type="text"
                  value={this.state.newPackageId}
                  id="packageId"
                  placeholder={t("orderDetails.packageId")}
                />
              </FormGroup>
            </Col>
            <Col lg="3" sm="12">
              <FormGroup>
                <Label for="recipientNumber">{t("orderDetails.recipientNumber")}</Label>
                <Input
                  type="text"
                  value={this.state.newRecipientNumber}
                  onChange={(e) => this.changeRecipientNumber(e)}
                  id="recipientNumber"
                  placeholder={t("orderDetails.recipientNumber")}
                />
              </FormGroup>
            </Col>
            <Col lg="3" sm="12">
              <FormGroup>
                <Label for="recipientPhone">{t("orderDetails.recipientPhone")}</Label>
                <Input
                  type="text"
                  value={this.state.newRecipientPhone}
                  onChange={(e) => this.changeRecipientPhone(e)}
                  id="recipientPhone"
                  placeholder={t("orderDetails.recipientPhone")}
                />
              </FormGroup>
            </Col>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" block disabled={this.state.isSubmitting} onClick={() => this.saveOrderDetails()}>
              {t('barcodeScanner.registerOrder')}
            </Button>
          </ModalFooter>
        </Modal>
        </>
      )

      return (
        <>
        {confirmRegisterOrderAlert}
        {registerOrderModal}

          <DeliverOrderModal
            showDeliveryModal={this.state.showDeliveryModal}
            toggleDeliveryModal={this.toggleDeliveryModal}/>
          <div onClick={() => this.toggleScanner()} className="navbar-icon-wrapper nav-item">
            <QrCodeIcon height={this.props.iconHeight} width={this.props.iconWidth} fill="currentColor" className="cursor-pointer notif-icon" />
          </div>
            <div style={{display: `${this.state.scanning ? "block" : "none"}`}} className="scanner-overlay-wrapper">
              <div className="scanner-crosshairs"></div>
              <Button color="primary" block size="lg" onClick={() => this.toggleScanner()} className="scanner-cancel-btn">
                {t('barcodeScanner.cancel')}
              </Button>
              <div className="scanner-video-wrapper">
                {this.state.scanning ? <QrReader
                  constraints={{ facingMode: 'environment' }}
                  delay={300}
                  onResult={(result) => this.handleScannerResult(result)}
                  videoContainerStyle={{padding: '0', position: ''}}
                /> : null}
              </div>
            </div>
        </>
      )
    }
}

const mapStateToProps = state => {
  console.log(state)
  return {
    accessToken: state.auth.login.accessToken,
  }
}

export default connect(mapStateToProps)(withTranslation('common')(ScanOrderQrCode))
