import React from 'react';
import { connect } from "react-redux"
import QrReader from 'react-qr-reader'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  Input
} from "reactstrap"
import {
  Cast
} from "react-feather"
import { getOrderDetails, setOrderDetails, getCubeOrderDetailsSGPT } from "redux/actions/orders"
import { getLockersBySize } from "redux/actions/locations"
import { toast } from "react-toastify"
import { withTranslation } from 'react-i18next'
import 'assets/scss/components/barcode-scanner.scss'
import DeliverOrderModal from "components/Delivery/DeliverOrderModal"
import SweetAlert from 'react-bootstrap-sweetalert'
import { ReactComponent as QrCodeIcon } from 'assets/img/svg/qr-code-icon.svg';
import { obfuscatePhone} from 'utility/string_filters';

class ScanOrderQrCodeIntegration extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scanning: false,
      showDeliveryModal: false,
      confirmRegisterOrderAlert: false,
      showRegisterOrderModal: false,
      packageId: '',
      recipientName: '',
      recipientNumber: '',
      recipientPhone: '',
      recipientEmail: '',
      statusDate: '',
      shippingMethod: '',
      isSubmitting: false,
      orderData: false,
    }
  }

    handleScannerResult = (result) => {
      if(result) this.onScanSuccess(result);
    }

    onScanSuccess = (result) => {
      if(!this.state.isSubmitting){
        this.toggleScanner()
        this.props.dispatch(getCubeOrderDetailsSGPT(this.props.accessToken, result, true))
        .then((res) => {
          console.log(res)
          if(res.message === 'order_already_created'){
            if(res.delivery.state === 4){
              toast.error(this.props.t("notifications.orders.orderAlreadyDeposited"))
            }else if(res.delivery.state === 7){
              toast.error(this.props.t("notifications.orders.orderAlreadyDelivered"))
            }else if(res.delivery.state === 14){
              toast.error(this.props.t("notifications.orders.orderAlreadyArchived"))
            }else{
              this.toggleDeliveryModal()
            }
          }else if(res.message === 'no_result_found'){
            toast.error(this.props.t("notifications.orders.noOrderFounded"))
          }else if(res.message === 'delivery_cancelled'){
            toast.error(this.props.t("notifications.orders.cancelledOrder"))
          }else{
            if(res.recipientPhone.length === 9){
              this.setState({ 
                orderData: res, 
                packageId: res.packageId, 
                recipientName: res.recipientName,
                recipientNumber: res.recipientNumber,
                recipientPhone: res.recipientPhone, 
                recipientEmail: res.recipientEmail, 
                statusDate: res.statusDate, 
                shippingMethod: res.shippingMethod
              })
              this.toggleRegisterOrderModal()
            }else{
              toast.error(this.props.t("notifications.orders.invalidPhoneNumber"))
            }
          }
        }).catch((err) => {
          toast.error(this.props.t("notifications.orders.errorFetchingData"))
        })
      }
    }

    toggleConfirmRegisterOrderAlert = () => {
      this.setState({confirmRegisterOrderAlert: !this.state.confirmRegisterOrderAlert})
    }

    toggleRegisterOrderModal = () => {
      this.setState({showRegisterOrderModal: !this.state.showRegisterOrderModal})
    }

    toggleDeliveryModal = () => {
      this.props.dispatch(getLockersBySize(this.props.accessToken, true))
      this.setState(prevState => ({
        showDeliveryModal: !prevState.showDeliveryModal
      }))
    }

    validateFields = () => {
      var phoneRegex = /^\d{9}$/

      if(
        this.state.recipientPhone.match(phoneRegex)
        && (
          this.state.packageId.substring(0,4).toUpperCase() === 'SGPT'
        )
        && (
          this.state.recipientNumber.substring(0,3).toUpperCase() === 'PRT'
          || this.state.recipientNumber.substring(0,2).toUpperCase() === 'PT'
        ) && (
          this.state.recipientPhone.substring(0,2) === '91' ||
          this.state.recipientPhone.substring(0,2) === '92' ||
          this.state.recipientPhone.substring(0,2) === '93' ||
          this.state.recipientPhone.substring(0,2) === '96'
        )
      ){
        return true
      }else{
        return false
      }
    }

    toggleScanner = () => {
      this.setState(prevState => ({
        scanning: !prevState.scanning
      }))
    }

    changeRecipientPhone = (e) => {
      this.setState({ recipientPhone: e.target.value });
    }

    changeRecipientNumber = (e) => {
      this.setState({ recipientNumber: e.target.value });
    }

    changeRecipientName = (e) => {
      this.setState({ recipientName: e.target.value });
    }

    saveOrderDetails = () => {
      if(this.validateFields()){
        this.setState({isSubmitting: true})
        this.props.dispatch(setOrderDetails(this.props.accessToken, {
          id: 0, 
          packageId: this.state.packageId, 
          recipientPhone: this.state.recipientPhone, 
          recipientName: this.state.recipientName, 
          recipientEmail: this.state.recipientEmail, 
          recipientNumber: this.state.recipientNumber,
          statusDate: this.state.statusDate,
          shippingMethod: this.state.shippingMethod,
        }))
        .then(()=>{
          toast.success(this.props.t("notifications.orders.setSuccess"))
          this.props.dispatch(getOrderDetails(this.props.accessToken, this.state.packageId, true)).then(() => {
            this.toggleRegisterOrderModal()
            this.toggleDeliveryModal()
          }).catch((err) => {
              toast.error(this.props.t("notifications.orders.errorFetchingData"))
          })
        }).catch((err) => {
          toast.error(this.props.t("notifications.orders.setError"))
        }).finally(() => {
          this.setState({isSubmitting: false})
        })
      }else{
        toast.error(this.props.t("notifications.orders.invalidInput"))
      }
    }

    render(){
      const { t } = this.props

      const confirmRegisterOrderAlert = <SweetAlert title={t('deliveryModal.orderNotExist')}
        warning
        show={this.state.confirmRegisterOrderAlert}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText={t('deliveryModal.confirm')}
        cancelBtnText={t('deliveryModal.cancel')}
        onConfirm={() => {
          this.toggleConfirmRegisterOrderAlert()
          this.toggleRegisterOrderModal()
        }}
        onCancel={() => this.toggleConfirmRegisterOrderAlert()}
      >
        {t('deliveryModal.confirmRegisterOrder')}
      </SweetAlert>

      const registerOrderModal = (
        <>
        <Modal
          isOpen={this.state.showRegisterOrderModal}
          toggle={this.toggleRegisterOrderModal}
          className="modal-dialog-centered modal-lg"
        >
          <ModalHeader toggle={this.toggleRegisterOrderModal} className="bg-primary">
          {t('barcodeScanner.registerOrder')}
          </ModalHeader>
          <ModalBody className="delivery-modal">
            <Row>
              <Col xs="12" sm="12" md="12" lg="6" xl="6" xxl="3" className="d-flex flex-column align-items-start pb-1">
                <Label for="packageId" className="font-weight-bold">{t("orderDetails.packageId")}</Label>
                <Input
                  disabled
                  type="text"
                  value={this.state.packageId}
                  id="packageId"
                  placeholder={t("orderDetails.packageId")}
                /> 
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="6" xxl="3" className="d-flex flex-column align-items-start pb-1">
                <Label for="recipientName" className="font-weight-bold">{t("orderDetails.recipientName")}</Label>
                <Input
                  disabled
                  type="text"
                  value={this.state.recipientName}
                  onChange={(e) => this.changeRecipientName(e)}
                  id="recipientName"
                  placeholder={t("orderDetails.recipientName")}
                />
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="6" xxl="3" className="d-flex flex-column align-items-start pb-1">
                <Label for="recipientNumber" className="font-weight-bold">{t("orderDetails.recipientNumber")}</Label>
                <Input
                  disabled
                  type="text"
                  value={this.state.recipientNumber}
                  onChange={(e) => this.changeRecipientNumber(e)}
                  id="recipientNumber"
                  placeholder={t("orderDetails.recipientNumber")}
                />
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="6" xxl="3" className="d-flex flex-column align-items-start pb-1">
                <Label for="recipientPhone" className="font-weight-bold">{t("orderDetails.recipientPhone")}</Label>
                <Input
                  disabled
                  type="text"
                  value={obfuscatePhone(this.state.recipientPhone)}
                  onChange={(e) => this.changeRecipientPhone(e)}
                  id="recipientPhone"
                  placeholder={t("orderDetails.recipientPhone")}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" block disabled={this.state.isSubmitting} onClick={() => this.saveOrderDetails()}>
              {t('barcodeScanner.continue')}
            </Button>
          </ModalFooter>
        </Modal>
        </>
      )

      return (
        <>
        {confirmRegisterOrderAlert}
        {registerOrderModal}

          <DeliverOrderModal
            showDeliveryModal={this.state.showDeliveryModal}
            toggleDeliveryModal={this.toggleDeliveryModal}
          />
          <div onClick={() => this.toggleScanner()} className="navbar-icon-wrapper nav-item">
            <QrCodeIcon height={this.props.iconHeight} width={this.props.iconWidth} fill="currentColor" className="cursor-pointer notif-icon" />
          </div>
            <div style={{display: `${this.state.scanning ? "block" : "none"}`}} className="scanner-overlay-wrapper">
              <div className="scanner-crosshairs"></div>
              <Button color="primary" block size="lg" onClick={() => this.toggleScanner()} className="scanner-cancel-btn">
                {t('barcodeScanner.cancel')}
              </Button>
              <div className="d-flex justify-content-center">
                {this.state.scanning ? <QrReader
                  constraints={{ facingMode: 'environment' }}
                  delay={300}
                  //onResult={(result) => this.handleScannerResult(result)}
                  onError={() => console.log('erro')}
                  onScan={(result) => this.handleScannerResult(result)}
                  style={{ width: "94vh", marginLeft: window.innerWidth > 1200 ? '260px' : ''}}
                /> : null}
              </div>
            </div>
        </>
      )
    }
}

const mapStateToProps = state => {
  console.log(state)
  return {
    accessToken: state.auth.login.accessToken,
  }
}

export default connect(mapStateToProps)(withTranslation('common')(ScanOrderQrCodeIntegration))
