import React from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"
import axios from "axios"
import * as Icon from "react-feather"
import { history } from "../../../history"
import { withTranslation } from 'react-i18next'
import { rolesList } from "configs/appConfig"

const UserDropdown = props => {
  const { t } = props
  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        href="#"
        onClick={props.auth.userRole !== rolesList[2].value ? e => history.push("/admins") : e => history.push("/employees")}
      >
        <Icon.Settings size={14} className="mr-50" />
        <span className="align-middle">{t("navbar.editProfile")}</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={props.logout}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">{t("navbar.logout")}</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: []
  }

  componentDidMount() {
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult })
    })
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }


  render() {
    return (
      <>

        <UncontrolledDropdown tag="li" className=" nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none navbar-user-name">
              <span className="user-name text-bold-600">
                {this.props.auth.userRole !== rolesList[2].value
                ?
                  this.props.auth.userRole.charAt(0).toUpperCase() + this.props.auth.userRole.slice(1)
                :
                  this.props.auth.assignedStore
                }
              </span>
              <span className="user-status">&nbsp;</span>
              <br></br><span className="navbar-muted-text">
              {this.props.auth.username} &nbsp;
              </span>
            </div>
            <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </>
    )
  }
}
export default (withTranslation('common')(NavbarUser))
