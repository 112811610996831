export const obfuscatePhone = (input) => {
  //return input
    if(input){
      return '*****' + input.substr(input.length - 4)
    }else{
      return ''
    }
}

export const obfuscateName = (input) => {
  if(input){
    const inputArray = input.split(" ")
    const firstName = inputArray[0]
    const lastName = inputArray[inputArray.length - 1]

    var firstNameVisibleChars = 3
    if(firstName.length <= 3){
      firstNameVisibleChars = 1
    }
    var lastNameVisibleChars = 3
    if(lastName.length <= 3){
      lastNameVisibleChars = 1
    }

    var obfuscatedFirstName = ''
    for (var i = 0; i < firstName.length - firstNameVisibleChars; i++) {
      obfuscatedFirstName += '*'
    }
    obfuscatedFirstName = firstName.substring(0,firstNameVisibleChars) + obfuscatedFirstName

    var obfuscatedLastName = ''
    for (var i = 0; i < lastName.length - lastNameVisibleChars; i++) {
      obfuscatedLastName += '*'
    }
    obfuscatedLastName = lastName.substring(0,lastNameVisibleChars) + obfuscatedLastName

    return obfuscatedFirstName + ' ' + obfuscatedLastName
  }else{
    return ''
  }

}

export const lowerCaseIfExists = (input) => {
  if(input != undefined && input !== ""){
    return input.toLowerCase()
  }
  return ""
}
