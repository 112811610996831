export const sectionsList = ["Portuguese",]
export const schoolsList = ["SJS-P","Library",]
export const statusList = [[1,"active"],[0, "suspend"],]
export const classesList = ["6º","7º","8º","9º","10","11º",]


export const readerType = 'both' // Switch type of reader to use, between values 'none, 'qrcode', 'barcode' or 'both'
export const showSpeechRecognition = false; // Switch show Speech Recognition on navbar
export const showMonitor = false; // Switch show Monitor on navbar
export const showNotifications = false; // Switch show Notifications on navbar

export const floorsList = ["a","b", "c"]
export const companiesList = ["Microsoft","Facebook",]
export const usernamesList = ["ROMEU","LOKK LAB 4",]
export const locationsList = ["OPEN","CITY CENTER",]
export const rolesList = [
  {
    value: "admin", 
    name: "Administrator"
  },
  {
    value: "security", 
    name: "Security"
  },
  {
    value: "store", 
    name: "Store"
  },
  {
    value: "lokk_importer", 
    name: "Importer Store"
  },
]

export const userRoles = [
  {
    name: 'Screen',
    role: 'ROLE_SCREEN'
  },
  {
    name: 'Dash',
    role: 'ROLE_DASH'
  },
  {
    name: 'Store',
    role: 'ROLE_STORE'
  }
]

//Places list that are showed on delivery order modal
export const rentalPlacesList = [
  {
    placeId: 1, 
    name: "Localização 1",
  }
]

export const userTypeList = [[1, "Employee"],[2, "Intern"],]
export const months = {
   1 : "Jan",
   2 : "Feb",
   3 : "Mar",
   4 : "Apr",
   5 : "May",
   6 : "Jun",
   7 : "Jul",
   8 : "Aug",
   9 : "Sep",
   10 : "Oct",
   11 : "Nov",
   12 : "Dec"
}
export const lockerSizeList = ['s', 'm', 'l']
export const orderStates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export const orderListStatesFilter = [1, 2, 3, 4, 5, 6, 8, 10, 11, 12, 13, 14, 15]
export const assetStatusList = [1, 2, 3, 4, 5, 6]
export const assetTagStatusList = [0, 1]
export const assetLogsStatusList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
export const functionsList = [
  "Admnistrativos",
  "Administ. Delegado",
  "Auxiliares",
  "Dirigentes",
  "Enfermeiros",
  "Internos - Compl. FP",
  "Médicos",
  "Serviços gerais",
  "Téc. Diag. Terap.",
  "Téc. Sup. Saúde",
  "Téc. Superiores",
  "Outras"
]
export const servicesList = [
  "Admnistrativos",
  "CUF Tejo",
  "Ambulatório",
  "Angiografia",
  "AP Adultos",
  "Bloco Operatório",
  "Bloco / Esterilização",
  "Consultas Externas",
  "Domus Care",
  "Enfermarias",
  "Esterilização",
  "Farmácia HCC",
  "Farmácia HCIS",
  "Gastro",
  "Gestão Hoteleira HCIS",
  "Hospital de Dia",
  "Internamento Piso 0",
  "Internamento Piso 1",
  "Internamento Piso 2",
  "Medicina Dentária",
  "Pediatria",
  "Recepção A",
  "Ser. Domiciliários",
  "Técnicos Imagiologia",
  "Técnicos MFR",
  "Técnicos - Otorrinolaringologia",
  "UCIP / Angiografia",
  "Vários",
  "Angiografia",
  "AP Adultos",
  "Bloco Operatório",
  "Bloco / Esterilização",
  "Consultas Externas",
  "Direcção de Enfermagem",
  "Enfermagem - Pool",
  "Enfermarias",
  "Enfermeiros instrumentistas",
  "Esterilização",
  "Gastro",
  "Gestão Risco / Controlo Infecção",
  "Hospital de Dia",
  "UCIP",
  "UCIP / Angiografia",
  "Otorrinolaringologia",
  "Vários",
  "Armazem HCIS",
  "Gestão de Fardamento CUF Sul",
  "Limpeza HCIS",
  "Logística Sul",
  "Manutenção",
  "Manutenção - ATM",
  "Maqueiro",
  "Secretariado Administração",
  "Cozinha",
  "Cafetaria Eric Kayser",
  "Restaurante Piso 2",
  "Anestesiologia",
  "Cardiologia",
  "Podologia",
  "Ser.Domicil.",
  "Técnicos",
  "Técnicos - Cardiologia",
  "Técnicos - Higiene Oral",
  "Técnicos - Imagiologia",
  "Técnicos - Imagiologia Pool",
  "Técnicos - Medic. Física e Reabilitação",
  "Técnicos - Neurofisiologia",
  "Técnicos - Nutrição",
  "Técnicos - Oftalmologia",
  "Técnicos - Otorrinolaringologia",
  "Técnicos - Patologia Clínica",
  "Farmácia HCIS",
  "Técnicos - Psicologia"
]
export const unitList = [
  "CUF Inf. Santo",
  "CUF Tejo",
  "CUF Almada",
  "CUF Cascais",
  "CUF Descobertas",
  "CUF Porto",
  "CUF Santarém",
  "CUF Sintra",
  "CUF Torres Vedras",
  "CUF Viseu",
  "Outra"
]
