import axios from "../../../utility/api"
import moment from 'moment';

const orderDetails = (item = false) => {
  return {
    id: item ? item.id : 0,
    createdAt: item ? item.createdAt : "",
    createdBy: item ? item.createdBy : "",
    deliveryType: item ? item.deliveryType : "",
    packageId: item ? item.packageId : "",
    recipientEmail: item ? item.recipientEmail : "",
    recipientName: item ? item.recipientName : "",
    recipientNumber: item ? item.recipientNumber : "",
    recipientPhone: item ? item.recipientPhone : "",
    state: item ? item.state : "",
    assignedLocker: (item && item.assignedLocker) ? item.assignedLocker : [],
    logs: (item && item.logs) ? item.logs : [],
    pickUpAt: item ? item.pickUpAt : "",
    storedAt: item ? item.storedAt : "",
    smsSent: item ? item.smsSent : "",
  }
}

const orderDetailsCube = (item = false) => {

  return {
    packageId: item ? item?.packageId : "",
    recipientName: item ? item?.recipientName : "",
    recipientNumber: item ? item?.recipientNumber : "",
    recipientPhone: item ? item?.recipientPhone : '',
    recipientEmail: item ? item?.recipientEmail : '',
    shippingMethod: item ? item?.shippingMethod : '',
    statusDate: item ? item?.statusDate : '',
  }
}

export const getOrders = (token, /*page, rowsPerPage, filters,*/max_results, reset) => (dispatch) =>
new Promise(function(resolve, reject) {
  /*const offset = rowsPerPage * (page - 1)
  let filterString = ''
  Object.entries(filters).forEach(([k, v]) => {
    if(v != null && v != ''){
      filterString = filterString + `&${k}=${v}`
    }
  })*/
  if (reset === true) {
    dispatch({
      type: "GET_ORDERS_LIST_SUCCESS",
      payload: false
    })
  }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/listOrders?max_results='+max_results+/*+rowsPerPage+'&offset='+offset+filterString+*/'&state=1,2,3,4,5,6,7,8,9,10,11,12,13&archived=false')
    .then(response => {
      console.log(response)
      if (response.data.ResultCode===1) {
        let ordersList = response.data.Data.deliveries.map((item, i) => {
          return orderDetails(item)
        })
        dispatch({
          type: "GET_ORDERS_LIST_SUCCESS",
          payload: {orders: ordersList, maxRows: response.data.Data.totalDeliveries}
        })
        resolve()
      } else {
        reject()
        throw(response);
      }
    })
    .catch(err => {
      reject()
    })
})

export const getArchivedOrders = (token, /*page, rowsPerPage, filters,*/max_results, reset) => (dispatch) =>
new Promise(function(resolve, reject) {
  /*const offset = rowsPerPage * (page - 1)
  let filterString = ''
  Object.entries(filters).forEach(([k, v]) => {
    if(v != null && v != ''){
      filterString = filterString + `&${k}=${v}`
    }
  })*/
  if (reset === true) {
    dispatch({
      type: "GET_ARCHIVED_ORDERS_LIST_SUCCESS",
      payload: false
    })
  }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/listOrders?max_results='+max_results+/*+rowsPerPage+'&offset='+offset+filterString+*/'&state=14&archived=false')
    .then(response => {
      if (response.data.ResultCode===1) {
        let archivedOrdersList = response.data.Data.deliveries.map((item, i) => {
          return orderDetails(item)
        })
        dispatch({
          type: "GET_ARCHIVED_ORDERS_LIST_SUCCESS",
          payload: {archivedOrders: archivedOrdersList, maxRows: response.data.Data.totalDeliveries}
        })
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => {
      reject()
    })
})

export const getOrdersHistory = (token, max_results,/*page, rowsPerPage, filters,*/ reset) => (dispatch) =>
new Promise(function(resolve, reject) {
  /*const offset = rowsPerPage * (page - 1)
  let filterString = ''
  Object.entries(filters).forEach(([k, v]) => {
    if(v != null && v != ''){
      filterString = filterString + `&${k}=${v}`
    }
  })*/
  if (reset === true) {
    dispatch({
      type: "GET_ORDERS_LIST_SUCCESS",
      payload: false
    })
  }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/listOrders?max_results='+max_results+/*+rowsPerPage+'&offset='+offset+filterString+*/'&archived=true')
    .then(response => {
      if (response.data.ResultCode===1) {
      
        let ordersList = response.data.Data.deliveries.map((item, i) => {
          return orderDetails(item)
        })
        dispatch({
          type: "GET_ORDERS_LIST_SUCCESS",
          payload: {orders: ordersList, maxRows: response.data.Data.totalDeliveries}
        })
        resolve()
      } else {
        reject()
        throw(response);
      }
    })
    .catch(err => {
      reject()
    })
})

export const getOrderDetails = (token, id, reset = false) => (dispatch) =>
new Promise(function(resolve, reject) {
    if (reset) {
      dispatch({
        type: "GET_ORDER_DETAILS_SUCCESS",
        payload: false
      });
    }

    if (id==="new") {
      dispatch({
        type: "GET_ORDER_DETAILS_SUCCESS",
        payload: orderDetails()
      });
      return;
    }
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/order?packageId='+id.toLowerCase()).then(res => {
      console.log(res)
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_ORDER_DETAILS_SUCCESS",
          payload: orderDetails(res.data.Data)
        });
        if(res.data.Data.state !== 1 && res.data.Data.state !== 2){
          resolve('orderDelivered')
        }else{
          resolve()
        }
      } else {
        reject('orderNotExist')
      }
    }).catch(err => {
      reject()
    })
})

export const getOrderDetailsByPT = (token, id, reset = false) => (dispatch) =>
new Promise(function(resolve, reject) {
    if (reset) {
      dispatch({
        type: "GET_ORDER_DETAILS_SUCCESS",
        payload: false
      });
    }

    if (id==="new") {
      dispatch({
        type: "GET_ORDER_DETAILS_SUCCESS",
        payload: orderDetails()
      });
      return;
    }
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/orderByPT?recipientNumber='+id.toLowerCase()).then(res => {
      console.log(res)
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_ORDER_DETAILS_SUCCESS",
          payload: orderDetails(res.data.Data)
        });
        if(res.data.Data.state !== 1 && res.data.Data.state !== 2){
          resolve('orderDelivered')
        }else{
          resolve()
        }
      } else {
        reject('orderNotExist')
      }
    }).catch(err => {
      reject()
    })
})

export const getCubeOrderDetailsSGPT = (token, sgpt, reset = false) => (dispatch) =>
new Promise(function(resolve, reject) {
    if (reset) {
      dispatch({
        type: "GET_ORDER_DETAILS_SUCCESS_CUBE",
        payload: false
      });
    }
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/orderByCubeSGPT?sgpt='+sgpt.toLowerCase()).then(res => {
      if (res.data.ResultCode===1) {
        if(res.data.Message === 'order_already_created'){
          resolve({ message: 'order_already_created', delivery: orderDetails(res.data.Data)})
          dispatch({
            type: "GET_ORDER_DETAILS_SUCCESS",
            payload: orderDetails(res.data.Data)
          });
          
        }else if(res.data.Data === 'no_result_found'){
          resolve({ message: 'no_result_found' })
        }
        else if(res.data.Data === 'delivery_cancelled'){
          resolve({ message: 'delivery_cancelled' })
        }else{
          resolve(orderDetailsCube(res.data.Data))
        }
      } else {
        reject('orderNotExist')
      }
    }).catch(err => {
      reject()
    })
})

export const getCubeOrderDetailsPT = (token, pt, reset = false) => (dispatch) =>
new Promise(function(resolve, reject) {
    if (reset) {
      dispatch({
        type: "GET_ORDER_DETAILS_SUCCESS_CUBE",
        payload: false
      });
    }
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/orderByCubePT?pt='+pt).then(res => {
      if (res.data.ResultCode===1) {
        if(res.data.Message === 'order_already_created'){
          resolve({ message: 'order_already_created', delivery: orderDetails(res.data.Data)})
          dispatch({
            type: "GET_ORDER_DETAILS_SUCCESS",
            payload: orderDetails(res.data.Data)
          });
          
        }else if(res.data.Data === 'No result found'){
          resolve({ message: 'no_result_found' })
        }else if(res.data.Data === 'delivery_cancelled'){
          resolve({ message: 'delivery_cancelled' })
        }else{
          resolve(orderDetailsCube(res.data.Data))
        }
      } else {
        reject('orderNotExist')
      }
    }).catch(err => {
      reject()
    })
})

export const setOrderDetails = (token,order) => (dispatch) =>
new Promise(function(resolve, reject){
  axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
  if (order.id === 0) {
    axios.post('api/delivery/order', {
      packageId: order.packageId.toLowerCase(),
      recipientName: !order.recipientName ? 'DECATHLON' : order.recipientName,
      recipientNumber: order.recipientNumber.toLowerCase(),
      recipientEmail: order.recipientEmail,
      recipientPhone: order.recipientPhone,
      statusDate: order.statusDate ? order.statusDate : new Date(),
      shippingMethod: order.shippingMethod ? order.shippingMethod : '' 
    })
    .then(res => {
      console.log(res)
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_ORDER_DETAILS_SUCCESS",
          payload: orderDetails(res.data.Data)
        });
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => {
      console.log(err)
      reject(err)
    })
  } else {
    axios.put('api/delivery/order', {
      id: order.id,
      packageId: order.packageId,
      recipientName: order.recipientName,
      recipientNumber: order.recipientNumber,
      recipientEmail: order.recipientEmail,
      recipientPhone: order.recipientPhone
    })
    .then(res => {
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_ORDER_DETAILS_SUCCESS",
          payload: orderDetails(res.data.Data)
        });
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => {
      reject()
    })
  }
})

export const assignLocker = (token, locationId, packageId, size, reset = false, lockerId = false) => (dispatch) =>
new Promise(function(resolve, reject) {
    if (reset) {
      dispatch({
        type: "GET_ORDER_DETAILS_SUCCESS",
        payload: false
      });
    }

    let str = ''
    if(lockerId){
      str = '&lockerId='+lockerId
    }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/assignLocker?locationId='+locationId+'&size='+size+'&packageId='+packageId+str).then(res => {
      if (res.data.ResultCode && res.data.ResultCode===1) {
        dispatch({
          type: "GET_ORDER_DETAILS_SUCCESS",
          payload: orderDetails(res.data.Data)
        });
        resolve()
      }else if(res.data.Description && res.data.Description === 'No locker avaliable'){
        reject('No locker available')
      }else {
        reject()
      }
    }).catch(err => {
      reject()
    })
})

export const getLockers = (token, reset = false) => (dispatch) =>
new Promise(function(resolve, reject) {
      if (reset) {
        dispatch({
          type: "GET_ORDER_PLACES_LOCKERS",
          payload: false
        })
      }

      axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
      axios.get('api/julian/lockers')
      .then(res => {
        if (res.data.ResultCode===1) {
          let lockers = res.data.Data && Object.keys(res.data.Data).reduce(function (r, a) {
            r[a] = res.data.Data[a]
            return r
          }, {})

          dispatch({
            type: "GET_ORDER_PLACES_LOCKERS",
            payload: lockers
          })
          resolve()
        }else{
          reject()
          throw(res)
        }
      }).catch(err => {
        reject()
      })
})

export const deleteOrder = (token, order) => (dispatch) =>
new Promise(function(resolve, reject) {
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.delete('api/delivery/order', {data: { packageId: order.packageId }}).then(response => {
      console.log(response)
      if (response.data.ResultCode===1) {
        resolve()
      } else {
        reject()
      }
    }).catch(err => {
      reject()
      console.log(err.response)
    })
})


export const getOrdersAnalytics = (token, id, filters ) => (dispatch) =>
new Promise(function(resolve, reject) {

    let filterString = ''

    Object.entries(filters).forEach(([k, v]) => {
      if(v != null && v != ''){
        //for date interval filter, parse date before submitting to filters string
        if(k === 'dateRange'){
          var newDate = ''
          if(v[1]){
            Object.entries(v).forEach(([k2, v2]) => {
              if(k2 > 0) newDate = newDate + '|'
              newDate = newDate + moment(v2).format('YYYY-MM-DD')
            })
          }
          filterString = filterString + `&${k}=${newDate}`
        }else{
          filterString = filterString + `&${k}=${v}`
        }
      }
    })
  
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get(`api/delivery/analytics?locationId=${id}` + filterString)
    .then(response => {
      console.log(response)
      if (response.data.ResultCode === 1) {
        dispatch({
          type: "GET_ORDERS_ANALYTICS_SUCCESS",
          payload: response.data.Data
        })
        resolve()
      } else {
        reject()
        throw(response);
      }
    })
    .catch(err => {
      reject()
    })
})

export const getDepositedOrders = (token, /*page, rowsPerPage, filters,*/max_results, reset) => (dispatch) =>
new Promise(function(resolve, reject) {
  /*const offset = rowsPerPage * (page - 1)
  let filterString = ''
  Object.entries(filters).forEach(([k, v]) => {
    if(v != null && v != ''){
      filterString = filterString + `&${k}=${v}`
    }
  })*/
  if (reset === true) {
    dispatch({
      type: "GET_ORDERS_LIST_SUCCESS",
      payload: false
    })
  }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/listOrders?max_results='+max_results+/*+rowsPerPage+'&offset='+offset+filterString+*/'&state=4&archived=false')
    .then(response => {
      console.log(response)
      if (response.data.ResultCode===1) {
        let ordersList = response.data.Data.deliveries.map((item, i) => {
          return orderDetails(item)
        })
        dispatch({
          type: "GET_ORDERS_LIST_SUCCESS",
          payload: {orders: ordersList, maxRows: response.data.Data.totalDeliveries}
        })
        resolve()
      } else {
        reject()
        throw(response);
      }
    })
    .catch(err => {
      reject()
    })
})