import React from 'react';
import { connect } from "react-redux"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  Col,
  Row,
  Input,
  Label
} from "reactstrap"
import axios from "utility/api"
import { assignLocker } from "redux/actions/orders"
import { getLockersByPlace, getSizes } from "redux/actions/locations"
import { toast } from "react-toastify"
import { withTranslation } from 'react-i18next'
import 'assets/scss/components/barcode-scanner.scss'
import ModalSpinner from "components/@vuexy/spinner/Modal-spinner"
import { rentalPlacesList } from 'configs/appConfig'
import Avatar from "components/@vuexy/avatar/AvatarComponent"
import { Box } from 'react-feather'
import { ColumnController } from 'ag-grid-community';
import { obfuscatePhone } from 'utility/string_filters';

class DeliverOrderModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lockerSize: '',
      isSubmitting: false,
      selectedLocationId: rentalPlacesList[0]?.placeId,
      assignedLocker: false,
      validField: true
    }
  }

  componentDidMount() {
    if(this.props.showDeliveryModal){
      this.props.dispatch(getLockersByPlace(this.props.accessToken, this.state.selectedLocationId)).then(() => {
        this.setState({ isSubmitting: false })
      }).catch(() => {
        toast.error(this.props.t("notifications.location.errorFetching"))
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.showDeliveryModal !== prevProps.showDeliveryModal) {
      if(this.props.showDeliveryModal){
        this.props.dispatch(getLockersByPlace(this.props.accessToken, this.state.selectedLocationId)).then(() => {
          this.setState({ isSubmitting: false })
        }).catch(() => {
          toast.error(this.props.t("notifications.location.errorFetching"))
        })
        this.props.dispatch(getSizes(this.props.accessToken)).then(() => {
          this.setState({ isSubmitting: false })
        }).catch(() => {
          toast.error(this.props.t("notifications.location.errorFetching"))
        })
      }
    }
  }

  setLockerSize = (lockerSize) => {
    this.setState({ lockerSize: lockerSize })
  }

  reloadData = () => {
    this.props.dispatch(getLockersByPlace(this.props.accessToken, this.state.selectedLocationId)).then(() => {
      this.setState({ isSubmitting: false })
    }).catch(() => {
      toast.error(this.props.t("notifications.location.errorFetching"))
    })
  }

  requestLocker = () => {
    if (this.state.lockerSize !== '') {
      this.setState({ isSubmitting: true })
      this.props.dispatch(assignLocker(this.props.accessToken, this.state.selectedLocationId, this.props.selectedOrder.packageId, this.state.lockerSize, true)).then((res) => {
        this.setState({ isSubmitting: false })
      }).catch((err) => {
        this.setState({ isSubmitting: false })
        if (err === 'No locker available') {
          toast.error(this.props.t("notifications.deliverOrder.noLockersAvailable"))
        } else {
          toast.error(this.props.t("notifications.deliverOrder.errorRequestingLocker"))
        }
        this.props.toggleDeliveryModal()
      })
    } else {
      this.setState({ validField: false })
      //toast.error(this.props.t("notifications.deliverOrder.selectLockerSize"))
    }
  }

  openDoorAndDeliver = () => {
    this.setState({ isSubmitting: true })
    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + this.props.accessToken }
    axios.get('api/delivery/storePackage?packageId=' + this.props.selectedOrder.packageId)
      .then(res => {
        this.setState({ isSubmitting: false })
        if (res.data.ResultCode === 1) {
          toast.success(this.props.t("notifications.deliverOrder.doorOpenedSuccessfuly"))
          this.props.toggleDeliveryModal()
        } else {
          toast.error(this.props.t("notifications.deliverOrder.errorOpeningDoor"))
        }
      })
      .catch(error => {
        this.setState({ isSubmitting: false })
        toast.error(this.props.t("notifications.deliverOrder.errorOpeningDoor"))
      })
  }

  deliverOnSelectedLocker = () => {
    this.props.dispatch(assignLocker(this.props.accessToken, this.props.locationId, this.props.selectedOrder.packageId, this.props.lockerSize, true, this.props.lockerId))
    .then(() => {
      this.setState({ isSubmitting: false })
      this.openDoorAndDeliver()
    }).catch((err) => {
      this.setState({ isSubmitting: false })
      if (err === 'No locker available') {
        toast.error(this.props.t("notifications.deliverOrder.noLockersAvailable"))
      } else {
        toast.error(this.props.t("notifications.deliverOrder.errorRequestingLocker"))
      }
      this.props.toggleDeliveryModal()
    })
  }

  changeLocation(id) {
    this.setState(
      {
        isSubmitting: true,
        selectedLocationId: id
      }
    )
    this.props.dispatch(getLockersByPlace(this.props.accessToken, id)).then(() => {
      this.setState({ isSubmitting: false })
    }).catch(() => {
      toast.error(this.props.t("notifications.location.errorFetching"))
    })
    this.props.dispatch(getSizes(this.props.accessToken)).then(() => {
      this.setState({ isSubmitting: false })
    }).catch(() => {
      toast.error(this.props.t("notifications.location.errorFetching"))
    })
  }

  getLockersAvailableBySize = (size, lockers) => {
    if (this.props?.lockersByPlace) {
      return this.props?.lockersByPlace.filter(function (locker) {
        return (
          locker.enabled === 1 &&
          locker.status === 0 &&
          locker.locker_metra_status === 2 &&
          locker.size === size
        )
      });
    }

    return null
  };

  toggleModal() {
    this.setState(
      {
        lockerSize: '',
        selectedLocationId: this.state.selectedLocationId,
        packageId: '',
        validField: true,
        assignedLocker: false,
      }
    )
    this.props.toggleDeliveryModal([])
    this.reloadData()
  }

  validateFields = () => {
    if (this.state.packageId !== '') {
      this.setState({ validField: true })
    } else {
      this.setState({ validField: false })
    }
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const { t, selectedOrder, sizes } = this.props
    
    return (

      <Modal
        isOpen={this.props.showDeliveryModal}
        toggle={() => this.toggleModal()}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader toggle={() => this.toggleModal()} className="bg-primary">
          {t('barcodeScanner.deliverOrder')}
        </ModalHeader>
        <ModalBody className="delivery-modal">
          {/* {!selectedOrder &&
            <>
              <ModalSpinner />
            </>
          } */}
          {selectedOrder &&
            <>
              <Row>
                <Col xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" className="d-flex flex-column align-items-start pb-1">
                  <Label for="packageId" className="font-weight-bold">{t('orderDetails.packageId')}</Label>
                  <Input
                    disabled
                    type="text"
                    value={selectedOrder?.packageId}
                    id="packageId"
                    placeholder={t("orderDetails.packageId")}
                  />
                </Col>
                <Col xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" className="d-flex flex-column align-items-start pb-1">
                  <Label for="recipientNumber" className="font-weight-bold">{t('orderDetails.recipientNumber')}</Label>
                  <Input
                    disabled
                    type="text"
                    value={selectedOrder?.recipientNumber}
                    id="recipientNumber"
                    placeholder={t("orderDetails.recipientNumber")}
                  />
                </Col>
                <Col xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" className="d-flex flex-column align-items-start pb-1">
                  <Label for="recipientName" className="font-weight-bold">{t('orderDetails.recipientName')}</Label>
                  <Input
                    disabled
                    type="text"
                    value={selectedOrder?.recipientName}
                    id="recipientName"
                    placeholder={t("orderDetails.recipientName")}
                  />
                </Col>
                <Col xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" className="d-flex flex-column align-items-start pb-1">
                  <Label for="recipientPhone" className="font-weight-bold">{t('orderDetails.recipientPhone')}</Label>
                  <Input
                    disabled
                    type="text"
                    value={obfuscatePhone(selectedOrder?.recipientPhone)}
                    id="recipientPhone"
                    placeholder={t("orderDetails.recipientPhone")}
                  />
                </Col>
              </Row>
              {rentalPlacesList.length > 1 && !this.props.isFixedLocker &&
                <>
                  <Row className="m-0 mb-1 px-1 d-flex justify-content-center">
                    <h5 className="text-primary">
                      {t('users.location')}:
                    </h5>
                  </Row>
                  <Row className="px-1">
                    {rentalPlacesList.map((item, index) =>
                      <Col key={index} xs="12" sm="12" md="12" lg="6">
                        <Card
                          text="info"
                          className={`p-1 px-2 cursor-pointer shadow-sm border-2 ${this.state.selectedLocationId === item.placeId ? 'border-primary shadow' : 'border-light'}`}
                          onClick={() => { this.changeLocation(item.placeId); this.setState({ lockerSize: '', validField: true }) }}
                        >
                          <Row className="d-flex align-items-center">
                            <Col sm="3" className="d-flex justify-content-center">
                              <Avatar
                                color='white'
                                border="primary"
                                icon={<Box color='#1382C2' />}
                                size="lg"
                              />
                            </Col>
                            <Col sm="9" className="d-flex justify-content-start">
                              <span className={this.state.selectedLocationId === item.placeId ? 'font-weight-bold' : 'text-muted'}>
                                {item.name}
                              </span>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    )}
                  </Row>
                </>
              }
              {!this.state.isSubmitting && !this.props.isFixedLocker &&
                <>
                  <Row className="m-0 mb-1 px-1 d-flex justify-content-center">
                    <h5 className="text-primary">
                      {t('users.size')}:
                    </h5>
                  </Row>
                  <Row className="px-1 mb-1">
                    {sizes.map((item, index) => (
                      <Col key={index} xs="12" sm="6" md="6" lg={12 / sizes.length}>
                        <Card
                          className={`d-flex flex-row align-items-center justify-content-between p-1 px-2 ${this.getLockersAvailableBySize(item)?.length !== 0 ? 'cursor-pointer' : ''} shadow-sm ${this.state.lockerSize === item ? 'border-primary shadow' : 'border-light'}`}
                          onClick={() => this.getLockersAvailableBySize(item)?.length !== 0 ? this.setState({ lockerSize: item, validField: true }) : null}
                        >
                          <strong>
                            <span className={`${this.getLockersAvailableBySize(item)?.length === 0 ? 'text-muted' : ''}`}>{item}&ensp;</span>
                          </strong>
                          <small className={this.state.lockerSize === item ? 'font-weight-bold' : 'text-muted'}>
                            {this.getLockersAvailableBySize(item)?.length}&nbsp;{t('lockerStatus.available')}
                          </small>
                        </Card>
                      </Col>
                    ))}

                  </Row>
                  {!this.state.validField ? (
                    <Row className="d-flex justify-content-center mb-1">
                      <span className="text-danger">{t('orderDetails.pleaseSelectSize')}</span>
                    </Row>
                  ) : null}
                </>
              }
              {this.props.isFixedLocker ?
                <Button color="primary" disabled={this.state.isSubmitting} block onClick={() => this.deliverOnSelectedLocker()}>
                  {t('barcodeScanner.confirmAndDeliver')}
                </Button>
                :
                <>
                  {!selectedOrder?.assignedLocker?.number &&
                    <Button color="primary" block disabled={this.state.lockerSize !== '' ? false : true} onClick={() => this.requestLocker()}>
                      {t('barcodeScanner.requestLocker')}
                    </Button>
                  }
                  {selectedOrder?.assignedLocker?.number && !this.state.isSubmitting &&
                    <>
                      <Button color="warning" className="mb-1" disabled={this.state.isSubmitting} block onClick={() => this.requestLocker()}>
                        {t('barcodeScanner.changeLocker')}
                      </Button>
                      <p><strong>{t('barcodeScanner.assignedLocker')}</strong></p>
                      <p className="delivery-assigned-locker-number mt-2 mb-2">{selectedOrder?.assignedLocker?.number}</p>
                      <p>({t('orderDetails.size')}: {selectedOrder.assignedLocker.size})</p>
                      <Button color="primary" disabled={this.state.isSubmitting} block onClick={() => this.openDoorAndDeliver()}>
                        {t('barcodeScanner.confirmAndDeliver')}
                      </Button>
                    </>
                  }
                </>
              }
            </>
          }
          {this.state.isSubmitting &&
            <Row className="d-flex h-100 align-items-center justify-content-center">
              <ModalSpinner />
            </Row>
          }
        </ModalBody>
      </Modal>

    )
  }
}

const mapStateToProps = state => {
  console.log(state)
  return {
    accessToken: state.auth.login.accessToken,
    selectedOrder: state.orders.selectedOrder,
    lockersBySize: state.locations.lockersBySize,
    sizes: state.locations.sizes,
    lockersByPlace: state.locations.lockersByPlace.Lockers
  }
}

export default connect(mapStateToProps)(withTranslation('common')(DeliverOrderModal))