const projectReducer = (state = { companyList : [], selectedCompany: null, employees: [] }, action) => {
  switch (action.type) {
    case "GET_COMPANY_SUCCESS":
      return { ...state, companyList: action.payload }
    case "GET_COMPANY_DETAILS_SUCCESS":
      return { ...state, selectedCompany: action.payload }
    case "GET_COMPANY_USERS_SUCCESS":
      return { ...state, employees: action.payload }
    default:
      return state
  }
}

export default projectReducer
